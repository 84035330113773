import React from 'react';

class ExpandIcon extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <svg
                className={this.props.className}
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path d='m305.066667 344.32a21.333333 21.333333 0 0 1 0-30.293333l176.213333-176.64a32 32 0 0 1 22.613333-9.386667h16.213334a32.853333 32.853333 0 0 1 22.613333 9.386667l176.213333 176.64a21.333333 21.333333 0 0 1 0 30.293333l-30.293333 30.293333a20.906667 20.906667 0 0 1 -29.866667 0l-146.773333-147.2-146.773333 147.2a21.333333 21.333333 0 0 1 -30.293334 0zm384 305.066667a21.333333 21.333333 0 0 0 -30.293334 0l-146.773333 147.2-146.773333-146.773334a20.906667 20.906667 0 0 0 -29.866667 0l-30.293333 30.293334a21.333333 21.333333 0 0 0 0 30.293333l176.213333 176.64a32.853333 32.853333 0 0 0 22.613333 9.386667h16.213334a32 32 0 0 0 22.613333-9.386667l176.213333-176.64a21.333333 21.333333 0 0 0 0-30.293333z' />
            </svg>
        );
    }
}

export default ExpandIcon;
